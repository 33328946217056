import 'landing_page/owl.carousel.min'
import { ajaxForm, errorMessageFor } from "ajax_forms"
import 'landing_page/cookie-consent'

$(() => {
  $(".owl-carousel").owlCarousel({
    loop: true,
    items: 1
  });
  ajaxForm({
    url: '/contacts',
    form: '#contact-form',
    button: '#submit-contact',
    success: res => $(".contact-form-container").html(res.html),
    error: xhr => $("#contact-message").text(errorMessageFor(xhr)).addClass("alert-danger").removeClass("alert-success").show(),
    limited: true
  });
});
